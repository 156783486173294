<template>
  <v-container fluid>
    <v-row v-if="!editCard" justify="space-between">
      <v-col cols="12" sm="8" md="6" lg="4">
        <h3 class="mt-6 mb-1">Основні дані</h3>
        <div>
          <span>ID:</span>
          <span class="font-weight-medium ml-2">{{ currentItem.id }}</span>
        </div>
        <div>
          <span>Назва:</span>
          <span class="font-weight-medium ml-2">{{ currentItem.name }}</span>
        </div>
        <div>
          <span>Телефон:</span>
          <span class="font-weight-medium ml-2">{{ formatPhoneNumber(currentItem.phone) }}</span>
        </div>
        <div>
          <span>Дата реєстрації:</span>
          <span class="font-weight-medium ml-2">{{ partnership.partnership_start_date | getShortDate }}</span>
        </div>
        <template v-if="currentItem.contacts && currentItem.contacts.personal && currentItem.contacts.personal.length">
          <h3 class="mt-6 mb-1">Контакти</h3>
          <div>
            <span>ПІБ:</span>
            <span class="font-weight-medium ml-2">{{ currentItem.contacts.personal[0].full_name }}</span>
          </div>
          <div>
            <span>Посада:</span>
            <span class="font-weight-medium ml-2">{{ currentItem.contacts.personal[0].position }}</span>
          </div>
          <div>
            <span>Телефон:</span>
            <span class="font-weight-medium ml-2">{{ currentItem.contacts.personal[0].phone }}</span>
          </div>
        </template>
      </v-col>
      <v-col cols="12" sm="8" md="6" lg="4">
        <v-avatar v-if="currentItem.logo" class="mb-3" size="100">
          <img :src="`${baseURL}/${currentItem.logo}`" alt="logo" />
        </v-avatar>
      </v-col>
    </v-row>

    <v-row v-else justify="space-between">
      <v-col cols="12" sm="8" md="6" lg="4">
        <h3 class="mt-6 mb-1">Основні дані</h3>
        <div>
          <span>ID:</span>
          <span class="font-weight-medium ml-2">{{ currentItem.id }}</span>
        </div>
        <div class="d-flex align-baseline custom-field">
          <span>Назва:</span>
          <v-text-field
            v-model="editedItem.name"
            class="ml-2"
            dense
            @input="$v.editedItem.name.$touch()"
            @blur="$v.editedItem.name.$touch()"
            :error-messages="nameErrors"
          ></v-text-field>
        </div>
        <div class="d-flex align-baseline custom-field">
          <span>Телефон:</span>
          <PhoneEdit
            class="ml-2"
            :value="editedItem.phone"
            :is-touched="isPhoneTouched"
            label="Телефон"
            validate
            @change="(val) => (editedItem.phone = val)"
            @validation="(val) => (isPhoneValid = val)"
          />
        </div>
        <div class="d-flex align-baseline custom-field">
          <span>Дата реєстрації:</span>
          <v-menu
            v-model="menuDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="partnershipStartDate"
                prepend-icon="mdi-calendar"
                hint="рiк-місяць-число"
                persistent-hint
                readonly
                v-bind="attrs"
                v-on="on"
                class="ml-2"
              ></v-text-field>
            </template>
            <v-date-picker v-model="partnershipStartDate" @input="menuDate = false"></v-date-picker>
          </v-menu>
        </div>
        <template v-if="currentItem.contacts && currentItem.contacts.personal && currentItem.contacts.personal.length">
          <h3 class="mt-6 mb-1">Контакти</h3>
          <div class="d-flex align-baseline custom-field">
            <span>ПІБ:</span>
            <v-text-field v-model="editedItem.contacts.personal[0].full_name" class="ml-2" dense></v-text-field>
          </div>
          <div class="d-flex align-baseline custom-field">
            <span>Посада:</span>
            <v-text-field v-model="editedItem.contacts.personal[0].position" class="ml-2" dense></v-text-field>
          </div>
          <div class="d-flex align-baseline custom-field">
            <span>Телефон:</span>
            <v-text-field v-model="editedItem.contacts.personal[0].phone" class="ml-2" dense></v-text-field>
          </div>
        </template>
      </v-col>
      <v-col cols="12" sm="8" md="6" lg="4">
        <v-avatar v-if="currentItem.logo" class="mb-3" size="100">
          <img :src="`${baseURL}/${currentItem.logo}`" alt="logo" />
        </v-avatar>
        <FileUploader
          @updateUpload="reloadPartner"
          :companyId="currentCompanyId"
          :partnerId="currentItem.id"
          accept="image/*"
          actionType="uploadPartnerLogo"
          label="Додати лого партнера"
          prepend-icon="mdi-camera"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="8" md="6" lg="4">
        <v-divider v-if="!editCard" class="mb-2"></v-divider>
        <CardAddress type="partner" :entity-id="currentItem.id" :is-data-saved="isDataSaved" :edit-card="editCard"></CardAddress>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import cloneDeep from '@/mixins/cloneDeep'
import convertAddress from '@/mixins/convertAddress'
import convertDate from '@/mixins/convertDate'
import { validationMixin } from 'vuelidate'
import { maxLength, minLength, required } from 'vuelidate/lib/validators'
import PhoneEdit from '@/components/common/PhoneEdit.vue'
import { formatPhoneNumber } from '@/helpers/formatPhoneNumber'

export default {
  name: 'PartnerGeneralInfo',

  mixins: [validationMixin, cloneDeep, convertAddress, convertDate],

  validations() {
    return {
      editedItem: {
        name: { required },
        phone: { required, minLength: minLength(13), maxLength: maxLength(13) },
      },
    }
  },

  components: {
    PhoneEdit,
    FileUploader: () => import('@/components/common/FileUploader'),
    CardAddress: () => import('@/components/partials/CardAddress'),
  },

  props: {
    currentItem: {
      type: Object,
      required: true,
      default: () => {},
    },
    partnership: {
      type: Object,
      required: true,
      default: () => {},
    },
    isDataSaved: {
      type: Boolean,
      required: true,
      default: false,
    },
    currentTabName: {
      required: true,
      default: null,
    },
    editCard: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    baseURL: process.env.VUE_APP_BASE_API_URL,
    editedItem: {},
    editedAddress: {},
    menuDate: false,
    partnershipStartDate: '',
    isPhoneValid: false,
    isPhoneTouched: false,
  }),
  created() {
    this.initialize()
  },

  computed: {
    currentCompanyId() {
      return this.$route.params.id
    },
    currentPartnerId() {
      return this.$route.params.partnerId
    },
    nameErrors() {
      const errors = []
      if (!this.$v.editedItem.name.$dirty) return errors
      !this.$v.editedItem.name.required && errors.push('Це поле обов"язкове')
      return errors
    },
  },

  watch: {
    editCard(val) {
      if (!val) {
        this.initialize()
      }
    },
    isDataSaved(val) {
      if (val && this.currentTabName === 'info') {
        const allData = {
          updatedPartner: this.editedItem,
          updatedPartnership: {
            partnership_start_date: this.partnershipStartDate,
          },
        }
        this.$v.editedItem.$touch()
        this.isPhoneTouched = true
        if (!this.$v.$anyError && this.isPhoneValid) this.$emit('saveData', allData)
      }
    },
  },

  methods: {
    formatPhoneNumber,
    ...mapActions('partners', ['loadSelectedPartner']),
    initialize() {
      this.editedItem = this.cloneObjectDeep(this.currentItem)
      this.partnershipStartDate = this.partnership.partnership_start_date
      this.$v.$reset()
      this.isPhoneTouched = false
    },
    async reloadPartner() {
      const payload = {
        companyId: this.currentCompanyId,
        partnerId: this.currentPartnerId,
      }
      await this.loadSelectedPartner(payload)
      this.editedItem = this.cloneObjectDeep(this.currentItem)
    },
  },
}
</script>

<style scoped lang="scss">
.custom-field {
  max-width: 800px !important;
}
</style>
